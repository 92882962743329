<template>
  <div class="home">
    <div class="inner">
<!--      <img alt="Vue logo" src="../assets/logo.png">-->
<!--      <HelloWorld msg="Welcome to Your Vue.js App"/>-->
      <div class="double-line first"></div>
      <h2>{{ text.firstline[$store.getters.LANG] }}</h2>
      <h2>{{ text.secondline[$store.getters.LANG] }}</h2>
      <h1>KOLOMA FAMILY</h1>
      <h2>{{ text.thirdline[$store.getters.LANG] }}</h2>
      <div class="double-line last"></div>
<!--      <h2>Вiдвiдай iнтернет - магазин</h2>-->
<!--      <button class="red">Shop artwork</button>-->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  data() {
    return {
      text: {
        firstline: {ua: 'Люди - яким не байдуже.', en: 'People - who care.'},
        secondline: {ua: 'Об\'єднанi однiєю iдеєю та метою.', en: 'United by one idea and goal.'},
        thirdline: {ua: 'Будь частиною цiєї iсторiї - частиною сiм\'ї.', en: 'Be part of this story - part of the family.\n'},
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

.inner {
  flex-direction: column;
  //gap: 20px;
  //padding-top: 80px;

  * {
    //margin: 0 auto;
    color: #ffffff;
    text-shadow: 5px 5px 5px rgba(0,0,0,.8);
    height: max-content;
  }
  h2, h1 {
    cursor: default;
  }
}
.double-line {
  padding: 1.5px 0;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  width: 85%;

  &.first {
    margin: 20px auto 10px;
  }
  &.last {
    margin: 10px auto 30px;
  }
}
h2 {
  font-family: serif;
  font-size: 2.5rem;
  margin: 0 auto;
}
h1 {
  font-size: 4rem;
  letter-spacing: 1px;
  margin: 25px auto;
}
button {
  margin: 10px auto;
}

@media screen and (max-width: $maxInnerWidth - 1px){
  .inner {
    * {
      max-width: 100%;
      white-space: pre-wrap;
    }
  }
  h1 {
    font-size: 3rem;
  }
  h2 {
    font-size: 1.5rem;
  }
  //h1, h2 {
  //  white-space: break-spaces;
  //}
}
</style>
